
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import CustomButton from '@/components/atom/CustomButton.vue'

export default defineComponent({
  components: {
    CustomButton
  },
  setup() {
    const route = useRoute()
    const studioName = route.params.studioName
    return { studioName }
  }
})
