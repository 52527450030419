<template>
  <div class="verify">
    <img class="verify__icon" src="@/assets/icons/verified-blue.svg" />
    <h1>Verification</h1>
    <h4 class="verify__studio-name">Welcome {{ studioName }}</h4>
    <p class="verify__content">
      We have sent an email to you. Please verify your email address to access the studio dashboard.
    </p>
    <CustomButton
      @onClick="$router.push({ name: 'Studio' })"
      testId="verificationNextButton"
      color="primary"
      size="small"
      label="Next"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import CustomButton from '@/components/atom/CustomButton.vue'

export default defineComponent({
  components: {
    CustomButton
  },
  setup() {
    const route = useRoute()
    const studioName = route.params.studioName
    return { studioName }
  }
})
</script>

<style lang="sass" scoped>
.verify
  display: flex
  justify-content: center
  align-items: flex-start
  flex-direction: column
  padding: 8rem
  width: 40rem
  &__icon
    width: 4.5rem
    height: auto
  &__content
    line-height: 1.3
  &__studio-name
    margin: 0
</style>
